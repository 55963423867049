<template>
  <div id="solution">
    <div class="solution" ref="solution">
      <section v-for="item in NavMenu" :key="item.id">
        <strong style="margin-bottom: 10px">{{ item.name }}</strong>
        <div>
          <a
            v-for="el in item.child"
            :key="el.id"
            @click="linkTo(el.sid)"
            class="hvr-wobble-to-bottom-right"
          >
            {{ el.name }}
            <i class="el-icon-sunny"></i>
          </a>
        </div>
        <span v-if="item.child.length > 5">
          更多
          <i class="el-icon-sort-up"></i>
        </span>
      </section>
    </div>
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
export default {
  data() {
    return {
      navshow: false,
    };
  },
  props: ["NavMenu"],
  methods: {
    linkTo(id) {
      // console.log(id)
      localStorage.setItem("page_id", id);
      this.$router.push({ path: "/solution", query: { ID: id } });
      this.$eventbus.$emit("page_id", id);
      this.$eventbus.$emit("nav_show", false);
      document.querySelectorAll("#menu_nav li").forEach((el) => {
        el.style.borderBottom = "0";
      });
      document.querySelector("#menu_nav li:nth-of-type(4)").style.borderBottom =
        "1px solid white";
    },
  },
  mounted() {
    // console.log(this.NavMenu);
    this.navshow = false;
    setTimeout(() => {
      this.navshow = true;
    }, 500);
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#solution {
  width: 100%;
  height: 100%;
  // background: blanchedalmond;
  .column;
  justify-content: center;
  .solution {
    width: 100%;
    height: 95%;
    // background: bisque;
    .row;
    justify-content: center;
    color: white;
    section {
      width: 15rem;
      height: 100%;
      margin-right: 0.625rem;
      // background: burlywood;
      .column;
      justify-content: flex-start;
      div {
        width: 100%;
        height: 85%;
        // background: blueviolet;
        .column;
        justify-content: flex-start;
        a {
          width: 14rem;
          height: 3rem;
          .row;
          background: rgba(0, 0, 0, 0.58);
          color: rgba(255, 255, 255, 1);
          padding-left: 0.625rem;
          margin-top: 0.5rem;
          // border: 0.125rem solid rgba(255, 255, 255, 1);
          border-radius: 0.3125rem;
          transition: all ease 500ms;
          i {
            color: crimson;
          }
        }
        a:nth-of-type(1) {
          margin-top: 0;
        }

        a::before {
          content: "";
          width: 0.3125rem;
          height: 60%;
          border-left: 0 solid #f8b551;
          border-radius: 0.0625rem;
        }

        a:hover {
          // border: 0.125rem solid white;
          background: white;
          color: black;
        }

        a:hover::before {
          border-left-width: 0.1875rem;
        }
      }

      span {
        align-self: flex-end;
      }
    }
  }
}
</style>